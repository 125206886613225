define("discourse/plugins/discourse-hCaptcha/initializers/h-captcha", ["exports", "rsvp", "discourse/lib/ajax", "discourse/lib/plugin-api", "discourse-common/lib/get-owner"], function (_exports, _rsvp, _ajax, _pluginApi, _getOwner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PLUGIN_ID = "discourse-hCaptcha";
  function initializeHCaptcha(api, container) {
    const siteSettings = container.lookup("service:site-settings");
    if (!siteSettings.discourse_hCaptcha_enabled) {
      return;
    }
    api.modifyClassStatic("model:user", {
      pluginId: PLUGIN_ID,
      createAccount() {
        const hCaptchaService = (0, _getOwner.getOwnerWithFallback)(this).lookup("service:h-captcha-service");
        hCaptchaService.submitted = true;
        if (hCaptchaService.invalid) {
          return _rsvp.Promise.reject();
        }
        const data = {
          token: hCaptchaService.token
        };
        const originalAccountCreation = this._super;
        return (0, _ajax.ajax)("/hcaptcha/create.json", {
          data,
          type: "POST"
        }).then(() => {
          return originalAccountCreation(...arguments);
        }).catch(() => {
          hCaptchaService.failed = true;
          return _rsvp.Promise.reject();
        }).finally(() => {
          hCaptchaService.reset();
        });
      }
    });
  }
  var _default = _exports.default = {
    name: PLUGIN_ID,
    initialize(container) {
      (0, _pluginApi.withPluginApi)("1.9.0", api => initializeHCaptcha(api, container));
    }
  };
});